import React, { useEffect, useState, useRef } from 'react';
import { IScreen, TSite, TUser } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, modal } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { TwitterPicker } from 'react-color';
import { SVG } from 'assets';
import { EvilIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import UpsertSiteDomainModal from './UpsertSiteDomain.Modal';
import apiClient from 'store/api-client';

const ManageListDomains = ({ site, setSite, submit }: { site: TSite, setSite: any, submit: Function }) => {

  const [listUsers, setListUsers] = useState<Array<TUser>>([]);

  const getSiteUsers = () => {
    Store.Api.User.listUsersBySite({ id: site.id }).then((res) => {
      if (res.data.success && res.data.data) {
        setListUsers(res.data.data);
      }
    });
  }

  useEffect(() => {
    if (!!site?.id && site?.id !== 'new') {
      getSiteUsers();
    }
  }, [site]);

  const showDomainModal = (domain: string) => {
    modal.show(
      <UpsertSiteDomainModal
        site={site}
        setSite={setSite}
        onSuccess={(newSite) => {
          submit(newSite, true);
        }}
        domain={domain}
      />
    )
  }

  const deleteDomain = async (domain: string) => {
    const shouldDelete = confirm(`Are you sure to delete this domain: ${domain}. All ebooks that use this domain wont be accessible.`);
    if (!shouldDelete) return;
    const domains = (site.domains || []).filter(v => v !== domain);
    const res = await apiClient.Api.Site.deleteDomain({ id: site.id }, { domain });
    if (!res.data.success) return alert(res.data.error);
    setSite({
      ...site,
      domains
    });
    // submit({
    //   ...site,
    //   domains
    // }, true);
  }

  return (
    <Col>
      <Col mv2 height={1} backgroundColor={COLOR.BORDER} />
      <Row mb2>
        <Text subtitle1 mr1>Site Domains</Text>
        <TouchField cirle middle onPress={() => showDomainModal('new')}>
          <AntDesign name="pluscircleo" size={20} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      {(site?.domains || []).map((d, dI) => {
        return (
          <Row key={dI} mb1 justifyContent={'space-between'}>
            <Text>{d}</Text>
            <Row>
              <TouchField cirle middle onPress={() => deleteDomain(d)}>
                <FontAwesome5 name="trash-alt" size={16} color="rgba(0,0,0,0.5)" />
              </TouchField>
              {/* <TouchField cirle middle onPress={() => showDomainModal(d)}>
                <SVG.Pencil width={15} height={15} />
              </TouchField> */}
            </Row>
          </Row>
        )
      })}
    </Col>
  );
};

export default ManageListDomains;
