import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, EbookDepartment, SwapIconEffect, Grid, RatioCol, ImageUploadChange, modal, ImageWithDimensions, EbookLanguage } from 'components';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IScreen, TEbook, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import moment, { lang } from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons, AntDesign, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import FormatText from './EditBook.FormatText';
import { SaveModal, ListRevisionModal } from './EditBook.Revisions';
import { PagesModal } from './EditBook.Pages';
import * as Animatable from 'react-native-animatable';
import { set } from 'react-native-reanimated';
import VideoShowing from 'components/elements/video/Video';
import VideoUploadChange from 'components/elements/form/VideoUploadChange';
import RequestUtils from 'store/api-client/Request.utils';

type TTextFocusData = {
  format: {
    bold: boolean,
    italic: boolean,
    underline: boolean,
    fontSize: number,
    color: string,
    hyperlink: string,
  },
  content: string,
  pageJump: string,
  parentParagraphHTML: string,
  pageIndex: number,
  target: {
    tagName: string,
    tagIndex: number,
  },
}

const EditEbook: IScreen = ({ langCode = '' }) => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [ebookData, setEbookData] = useState<TEbook>();

  const [pageData, setPageData] = useState<any>();
  const pageDataRef = useRef(pageData);
  useEffect(() => {
    pageDataRef.current = pageData;
  }, [pageData]);
  const [iframeWidth, setIframeWidth] = useState(1);
  const [formatTab, selectFomartTab] = useState<'images' | 'text' | 'videos' | 'html'>('images');
  const [textFocusData, setTextFocusData] = useState<TTextFocusData>();
  const [isDisabledNavigation, setIsDisabledNavigation] = useState<boolean>(false);
  const [imageClickedData, setImageClickedData] = useState({ pageIndex: 0, data: { url: '', index: 0 } });
  const [htmlTab, setHtmlTab] = useState<'html' | 'visual'>('visual');
  const [readyToEdit, setReadyToEdit] = useState(false);

  const revisionMessage = useRef('');
  const shouldUseAIRef = useRef(false);

  const htmlCodeEditorRef = useRef(null);

  const ebookDataRef = useRef(null);

  useEffect(() => {
    ebookDataRef.current = ebookData;
  }, [ebookData]);

  useEffect(() => {
    if (formatTab === 'html') {
      setHtmlTab('html');
    } else {
      setHtmlTab('visual');
    }
  }, [formatTab]);
  console.log('ebookData', ebookData);
  const getData = async () => {
    try {
      setUI({ fetching: true, errorMes: '' });
      await TimeHelper.wait(300);
      const res = await Store.Api.Ebook.detail({ id }, {});
      console.log('res', res, res.data);
      const { data } = res.data;
      setEbookData(data);
      setUI({ fetching: false, errorMes: '' });
      const res2 = await Store.Api.Ebook.getRevisions({ id }, { langCode })
      if (res2.data.data) {
        setEbookData(e => ({
          ...e,
          revisions: res2.data.data,
        }))
      }
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  const tryPushChangesToGit = useCallback(async (message: string) => {
    const currentEbookData = ebookDataRef.current;
    console.log('Current ebookData in tryPushChangesToGit:', currentEbookData);
    
    if (!currentEbookData?.gitUrl) {
      console.log('No gitUrl found, skipping git push');
      return true;
    }

    console.log('Found gitUrl, attempting to push:', currentEbookData.gitUrl);
    const gitPushResult = await pushChangesToGit(message);
    return gitPushResult.success;
  }, []);

  const saveLatestHTML = useCallback(async (payload: string) => {
    try {
      const res = await Store.Api.Ebook.saveHtml({
        id,
      }, {
        html: payload,
        message: revisionMessage.current,
        shouldUseAI: shouldUseAIRef.current,
        langCode,
      });
      
      if (res.data.success) {
        const pushSuccess = await tryPushChangesToGit(revisionMessage.current);
        if (!pushSuccess) {
          alert('Your ebook is saved successfully but failed to push to Git');
          window.location.reload();
          return;
        }
        alert('Your ebook is saved successfully');
        window.location.reload();
      }
    } catch (error) {
      alert(`Failed to save ebook: ${error.message}`);
    }
  }, [tryPushChangesToGit]);

  useEffect(() => {
    getData();
  }, [id]);

  const onPageChange = ({ pageIndex, data }) => {
    if (!readyToEdit) setReadyToEdit(true);
    setPageData({
      pageIndex,
      data: {
        backgroundImage: data.backgroundImage.replace(`url("`, '').replace(`")`, ''),
        images: data.images,
        videos: data.videos,
        html: data.html,
      },
    });

  }

  const sendEventToIframe = (data: any) => {
    iframeRef.current?.contentWindow?.postMessage(
      data,
      '*',
    );
  }

  const onIframeLayout = event => {
    const { width } = event.nativeEvent.layout;
    setIframeWidth(width);
  }

  const showSaveModal = async () => {
    // Set to first page before saving
    sendEventToIframe({
      type: 'JUMP_TO_PAGE',
      payload: 0
    });
    
    sendEventToIframe({
      type: 'DISABLE_NAVIGATION',
      payload: { shouldDisable: false },
    });
    
    modal.show(
      <SaveModal
        onSave={async ({ message, shouldUseAI }) => {
          revisionMessage.current = message;
          shouldUseAIRef.current = shouldUseAI;
          sendEventToIframe({ type: 'SAVE_HTML' });
        }}
        initialShouldUseAI={ebookData.revisions?.length > 0 ? ebookData.revisions[ebookData.revisions.length - 1].shouldUseAI : false}
      />
    )
  };

  const openRevisionModal = () => {
    // sendEventToIframe({ type: 'SAVE_HTML' });
    modal.show(
      <ListRevisionModal
        ebook={ebookData}
      />
    )
  }

  const openPageModal = () => {
    modal.show(
      <PagesModal
        ebook={ebookData}
        langCode={langCode}
        jumpToPage={(index) => {
          sendEventToIframe({ type: 'JUMP_TO_PAGE', payload: index });
        }}
      />
    )
  }

  const onSendCommand = (name, value = undefined) => {
    if (name === 'undo') {
      sendEventToIframe({
        type: 'EXE_UNDO',
        payload: {}
      });
      return;
    }
    if (name === 'redo') {
      sendEventToIframe({
        type: 'EXE_REDO',
        payload: {}
      });
      return;
    }
    sendEventToIframe({
      type: 'EXE_COMMAND',
      payload: {
        name,
        value,
      }
    });
    iframeRef.current?.focus();
  }
  const productionHost = () => {
    if (ebookData?.url) {
      const domain = new URL(ebookData.url).hostname;
      return `https://${domain}/e`;
    }
    const slug = window.location.host.split('.')[0];
    if (slug === 'worldpay') return 'https://ebook.worldpaytravel.com/e';
    return slug === 'global' ? 'https://ebooks.prismpresent.com/e' : `https://${slug}.v2.prismpresent.com/e`;
  };
  console.log('productionHost', ebookData?.url, productionHost());
  const serverPath = ebookData?.tempServerPath + ( !!langCode && langCode !== 'undefined' ? langCode + '.html' : '' );
  const editUrlPrefix = (process.env.NODE_ENV !== 'development' ? productionHost() : 'http://localhost:3001/e')
  const editUrl = editUrlPrefix + serverPath;
  const iframeRef = useRef<HTMLIFrameElement>();

  const pushChangesToGit = async (message: string) => {
    try {
      const response = await fetch(RequestUtils.host + '/api/ebooks/push-changes-to-git', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ebookId: id,
          commitMessage: message,
        }),
      });

      const result = await response.json();

      if (result.success) {
        return { success: true };
      } else {
        return { 
          success: false, 
          error: result.error || result.message || "Failed to push changes to Git"
        };
      }
    } catch (error) {
      return { 
        success: false, 
        error: error.message || "Failed to push changes to Git"
      };
    }
  };


  const timeoutRef = useRef(new Date().getTime());
  const didIframeOnLoad = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      if (didIframeOnLoad.current) return;
      iframeOnLoad({
        target: iframeRef.current,
      })
    }, 7000);
  }, []);

  const iframeOnLoad = (e) => {
    if (new Date().getTime() - timeoutRef.current > 15000 || didIframeOnLoad.current) {
      return;
    }
    didIframeOnLoad.current = true;
    // console.log(e.target);
    console.log('ON LOAD');
    const el = e.target;
    // @ts-ignore
    const iframeUrl = new URL(el.getAttribute("src") as string);
    window.addEventListener('message', function (ee) {
      if (ee.origin !== iframeUrl.origin) return;
      console.log('ee.data', ee.data);
      if (ee.data && ee.data.type === 'LATEST_HTML') {
        saveLatestHTML(ee.data.payload)
      }
      if (ee.data && ee.data.type === 'ON_PAGE_CHANGE') {
        console.log('ON_PAGE_CHANGE', ee.data.payload);
        onPageChange(ee.data.payload);
      }
      if (ee.data && ee.data.type === 'FOCUS_TEXT') {
        console.log('FOCUS_TEXT', ee.data.payload);
        setTextFocusData(ee.data.payload);
        if (!!ee.data.payload) {
          selectFomartTab('text');
        }
      }
      if (ee.data && ee.data.type === 'ON_IMAGE_CLICK') {
        setImageClickedData(ee.data.payload);
      }
      if (ee.data && ee.data.event === 'MONACO_EDITOR_CONTENT_CHANGE_HTML_ebook') {
        console.log('pageDataRef', pageDataRef.current);
        setPageData({
          ...pageDataRef.current,
          data: {
            ...pageDataRef.current.data,
            html: ee.data.payload,
          }
        })
        if (pageDataRef.current && typeof pageDataRef.current.pageIndex === 'number') {
          sendEventToIframe({
            type: 'UPDATE_PAGE_HTML',
            payload: {
              html: ee.data.payload,
              pageIndex: pageDataRef.current.pageIndex,
            }
          });
        }
      }
    });
    sendEventToIframe({ type: 'ADD_PAGECHANGE_CALLBLACK' });
  }
  
  useEffect(() => {
    Store.Api.Ebook.lockEbook({ id });

    const intervalId = setInterval(() => {
      Store.Api.Ebook.lockEbook({ id });
    }, 4 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
      Store.Api.Ebook.unlockEbook({ id });
    };
  }, [id]);

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row>
          <EbookDepartment ebook={ebookData} />
          <Text h3>{ebookData?.name}</Text>
        </Row>
        {/* <EbookLanguage currentLang={langCode} ebook={ebookData} onChangeLang={(langCode) => {
          window.location.pathname = `/ebooks/${ebookData.id}/${langCode}/edit`;
        }} /> */}
        <Col />
      </Row>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row>
          <Button
            text="View"
            outline
            width={60} height={30} borderRadius={20}
            onPress={async () => {
              window.open(editUrl, '_blank');
            }}
          />
          <Button
            text="Analytics"
            outline
            width={100} height={30} borderRadius={20} ml1
            onPress={async () => {
              navigation.navigate(SCREEN.EbookAnalytic, { id });
            }}
          />
        </Row>
        {ebookData?.tempServerPath && (
          <Row>
            {ebookData.revisions && ebookData.revisions.length > 0 && (
              <Button
                text="Revisions"
                outline
                width={100} height={30} borderRadius={20} mr1
                onPress={async () => {
                  openRevisionModal();
                }}
              />
            )}
            <Button
              text="Pages"
              outline
              width={80} height={30} borderRadius={20} mr1
              onPress={async () => {
                openPageModal();
              }}
            />
            <Button
              text="Save"
              width={60} height={30} borderRadius={20}
              onPress={async () => {
                // sendEventToIframe({ type: 'SAVE_HTML' });
                showSaveModal();
              }}
            />
          </Row>
        )}
      </Row>
      <Col flex1 p2>
        {ebookData?.tempServerPath && (
          <Col flex1>
            <Row flex1 stretch>
              <Col flex1>
                <RatioCol ratio={16 / 9} width='100%' backgroundColor={COLOR.GREY_BG} onLayout={onIframeLayout}>
                  <Col
                    width={iframeWidth === 1 ? 1920 * 0.4 : iframeWidth}
                    height={iframeWidth === 1 ? 1080 * 0.4 : iframeWidth / 16 * 9}
                  >
                    <iframe
                      src={editUrl}
                      ref={iframeRef}
                      style={{
                        // width: iframeWidth === 1 ? 1920 * 0.4 : iframeWidth,
                        // height: iframeWidth === 1 ? 1080 * 0.4 : iframeWidth / 16 * 9,
                        width: 1920,
                        height: 1080,
                        border: 'none',
                        transform: `scale(${iframeWidth / 1920})`,
                        transformOrigin: 'top left',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                      onLoad={iframeOnLoad}
                    />
                    {!readyToEdit && (
                      <Col
                        absoluteFill middle backgroundColor={'rgba(0,0,0,0.6)'}
                        onPress={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ActivityIndicator color='white' size='large' />
                      </Col>
                    )}
                    {htmlTab === 'html' && (
                      <Col absoluteFill backgroundColor={'#1e1e1e'}>
                        <iframe
                          ref={htmlCodeEditorRef}
                          src={`${editUrlPrefix}/monaco/index.html?cid=ebook&language=html`}
                          style={{ width: '100%', height: '100%', border: 'none' }}
                          onLoad={() => {
                            console.log('editor onLoad', pageData);
                            htmlCodeEditorRef.current?.contentWindow?.postMessage({
                              event: 'ELEMENTOR_UPDATE_CONTENT_HTML_ebook',
                              payload: pageData.data.html,
                            }, '*');
                          }}
                        />
                      </Col>
                    )}
                  </Col>

                </RatioCol>
              </Col>
              <Col width={250} ml1 bgWhite p1>
                <Row height={50} stretch mb1>
                  <TouchField borderRadius={0} flex1 middle onPress={() => selectFomartTab('images')}
                    borderBottomColor={formatTab === 'images' ? COLOR.GREY : 'transparent'} borderBottomWidth={2}
                  >
                    <Text bold={formatTab === 'images'}>Images</Text>
                  </TouchField>
                  <TouchField borderRadius={0} flex1 middle onPress={() => selectFomartTab('videos')}
                    borderBottomColor={formatTab === 'videos' ? COLOR.GREY : 'transparent'} borderBottomWidth={2}
                  >
                    <Text bold={formatTab === 'videos'}>Videos</Text>
                  </TouchField>
                  <TouchField borderRadius={0} flex1 middle onPress={() => selectFomartTab('text')}
                    borderBottomColor={formatTab === 'text' ? COLOR.GREY : 'transparent'} borderBottomWidth={2}
                  >
                    <Text bold={formatTab === 'text'}>Text</Text>
                  </TouchField>
                  <TouchField borderRadius={0} flex1 middle onPress={() => selectFomartTab('html')}
                    borderBottomColor={formatTab === 'html' ? COLOR.GREY : 'transparent'} borderBottomWidth={2}
                  >
                    <Text bold={formatTab === 'html'}>HTML</Text>
                  </TouchField>
                </Row>
                {formatTab === 'images' && (
                  <>
                    {pageData?.data?.backgroundImage && (
                      <Col>
                        <Text pb1>Background Image</Text>
                        <ImageUploadChange
                          onChange={(newUrl) => {
                            console.log('new Url', newUrl);
                            sendEventToIframe({
                              type: 'CHANGE_BACKGROUND',
                              payload: {
                                url: newUrl,
                                pageIndex: pageData?.pageIndex,
                              }
                            })
                          }}
                          imageUrl={pageData?.data?.backgroundImage}
                          renderImage={(imageUrl) => {
                            return (
                              <ImageWithDimensions
                                url={imageUrl}
                                style={{
                                  width: '100%',
                                  height: 100,
                                }}
                                resizeMode='cover'
                              />
                            )
                          }}
                        />
                      </Col>
                    )}
                    {pageData?.data?.images && pageData?.data?.images.length > 0 && (
                      <Col mt1>
                        {imageClickedData && imageClickedData.data && imageClickedData.data.url && (imageClickedData.pageIndex === pageData?.pageIndex) && (
                          <Col pb2 key={imageClickedData.data.url}>
                            <Animatable.View animation={'fadeIn'}>
                              <Text pb1>Image you just clicked</Text>
                              <ImageUploadChange
                                flex1
                                onChange={(newUrl) => {
                                  sendEventToIframe({
                                    type: 'CHANGE_IMAGE',
                                    payload: {
                                      url: newUrl,
                                      pageIndex: imageClickedData.pageIndex,
                                      index: imageClickedData.data.index,
                                    }
                                  })
                                }}
                                imageUrl={pageData?.data?.images[imageClickedData.data.index].url}
                                renderImage={(imageUrl) => {
                                  return (
                                    <ImageWithDimensions
                                      url={imageUrl}
                                      style={{
                                        width: '100%',
                                        height: 200,
                                      }}
                                      resizeMode='contain'
                                    />
                                  )
                                }}
                              />
                            </Animatable.View>
                          </Col>
                        )}
                        <Text pb1>Images in page</Text>
                        <Grid marginHorizontal={-5} xs='50%'>
                          {pageData?.data?.images.map((val, i) => {
                            // console.log('val', val);
                            return (
                              <Col key={'image-' + i} p0 m0 aspectRatio={1} backgroundColor={COLOR.GREY_BG}>
                                <ImageUploadChange
                                  flex1
                                  onChange={(newUrl) => {
                                    sendEventToIframe({
                                      type: 'CHANGE_IMAGE',
                                      payload: {
                                        url: newUrl,
                                        pageIndex: pageData?.pageIndex,
                                        index: val.index,
                                      }
                                    })
                                  }}
                                  imageUrl={val.url}
                                  renderImage={(imageUrl) => {
                                    return (
                                      <ImageWithDimensions
                                        url={imageUrl}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                        }}
                                        resizeMode='contain'
                                      />
                                    )
                                  }}
                                />
                              </Col>
                            );
                          })}
                        </Grid>
                      </Col>
                    )}
                  </>
                )}

                {formatTab === 'videos' && (
                  <>
                    {pageData?.data?.videos && pageData?.data?.videos.length > 0 && (
                      <Col mt1>
                        <Text pb1>Videos in page</Text>
                        <Grid marginHorizontal={-5} xs='100%'>
                          {pageData?.data?.videos.map((val, i) => {
                            // console.log('val', val);
                            return (
                              <Col key={'video-' + i} p0 m0 backgroundColor={COLOR.GREY_BG}>
                                <VideoUploadChange
                                  flex1
                                  onChange={(newUrl) => {
                                    sendEventToIframe({
                                      type: 'CHANGE_VIDEO',
                                      payload: {
                                        url: newUrl,
                                        pageIndex: pageData?.pageIndex,
                                        index: val.index,
                                      }
                                    })
                                  }}
                                  videoUrl={val.url}
                                  renderVideo={(videoUrl) => {
                                    return (
                                      <VideoShowing
                                        url={videoUrl}
                                      />
                                    )
                                  }}
                                />
                              </Col>
                            );
                          })}
                        </Grid>
                      </Col>
                    )}
                  </>
                )}
                {formatTab === 'text' && (
                  <>
                    {!textFocusData ? (
                      <>
                        <Text caption mb1>Please select a text in the ebook to enable formatting</Text>
                        <Text caption mb1>You can type first then select the text to format</Text>
                        
                        <Row mb1>
                          {/* <Col flex1 /> */}
                          <IconBox mr0
                            onPress={() => onSendCommand('undo')}
                          >
                            <FontAwesome5 name="undo" size={12} color={'black'} />
                          </IconBox>
                          <IconBox mr0
                            onPress={() => onSendCommand('redo')}
                          >
                            <FontAwesome5 name="redo" size={12} color={'black'} />
                          </IconBox>
                          <Text caption ml1>Undo/Redo</Text>
                        </Row>
                        <Col mb1>
                          <TouchField onPress={() => {
                            const shouldDisable = !isDisabledNavigation;
                            setIsDisabledNavigation(shouldDisable);
                            sendEventToIframe({
                              type: 'DISABLE_NAVIGATION',
                              payload: { shouldDisable },
                            });
                          }} borderRadius={2} padding={5}>
                            <Row>
                              {/* <Col width={20} height={20} middle borderWidth={1} borderRadius={2} overflow={'hidden'}>
                                {isDisabledNavigation ? (
                                  <AntDesign name="check" size={15} color="black" />
                                ) : null}
                              </Col> */}
                              <FontAwesome name={isDisabledNavigation ? 'toggle-on' : "toggle-off"} size={24} color="black" />
                              <Col flex1 ml1>
                                <Text caption>Disable page navigation{'\n'}(to edit button)</Text>
                              </Col>
                            </Row>
                          </TouchField>
                        </Col>
                      </>
                    ) : (
                      <FormatText
                        content={textFocusData.content}
                        format={textFocusData.format}
                        onSendCommand={onSendCommand}
                        pageJump={textFocusData.pageJump}
                        ebookData={ebookData}
                        sendEventToIframe={sendEventToIframe}
                        parentParagraphHTML={textFocusData.parentParagraphHTML}
                      />
                    )}
                  </>
                )}

                {formatTab === 'html' && (
                  <>
                    <Text caption mb1>Direct edit HTML code allows you to change any type of content</Text>
                    <Row>
                      <Button
                        outline={htmlTab !== 'html'}
                        text="HTML"
                        width={100} height={30} borderRadius={20}
                        onPress={async () => {
                          setHtmlTab('html');
                        }}
                      />
                      <Button
                        ml0
                        outline={htmlTab !== 'visual'}
                        text="Visual"
                        width={100} height={30} borderRadius={20}
                        onPress={async () => {
                          setHtmlTab('visual');
                        }}
                      />
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Col>
    </CMSLayout>
  )
}

const IconBox = ({ children, ...props }) => (
  <TouchField borderRadius={2} width={30} height={30} borderWidth={0.5} borderColor={'rgba(0,0,0,0.2)'} middle {...props}>
    {children}
  </TouchField>
);

EditEbook.routeInfo = {
  title: 'Edit Ebook',
  path: '/ebooks/:id/edit',
};

export default EditEbook;
