import Request from '../Request.utils'
export interface IListRequestQuery {
  page?: number;
  siteId?: string;
  withoutRevisions?: number;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IDetailRequestQuery {
  analyticHealhCheck?: number;
}
export interface ISaveHtmlRequestBody {
  html: string;
  langCode?: string;
  message: string;
  shouldUseAI?: boolean;
}
export interface ISaveHtmlRequestParams {
  id: string;
}
export interface IApplyRevisionRequestBody {
  revisionId: string;
}
export interface IApplyRevisionRequestParams {
  id: string;
}
export interface IGetPagesRequestParams {
  id: string;
}
export interface IGetPagesRequestQuery {
  langCode?: string;
}
export interface ISavePagesRequestBody {
  message: string;
  pages?: {
    index?: number;
  }[];
}
export interface ISavePagesRequestParams {
  id: string;
}
export interface IGetRevisionsRequestParams {
  id: string;
}
export interface IGetRevisionsRequestQuery {
  langCode?: string;
}
export interface IUpdateDepartmentRequestBody {
  departmentId: string;
  departmentTitle: string;
}
export interface IUpdateDepartmentRequestParams {
  id: string;
}
export interface IAddLanguageRequestBody {
  code: string;
  language: string;
}
export interface IAddLanguageRequestParams {
  id: string;
}
export interface ICloneEbookRequestBody {
  name: string;
  tempServerPath: string;
}
export interface ICloneEbookRequestParams {
  id: string;
}
export interface IAddAnalyticsRequestBody {}
export interface IAddAnalyticsRequestParams {
  id: string;
}
export interface IDeleteEbookRequestParams {
  id: string;
}
export interface ICreateEbookRequestBody {
  copyTempPath: string;
  domain?: string;
  gitUrl?: string;
  name: string;
  subDomain?: string;
  tempServerPath: string;
}
export interface IImportFromGitRequestBody {
  url: string;
}
export interface IPushEbookChangesToGitRequestBody {
  commitMessage: string;
  ebookId: string;
}
export interface ILockEbookRequestParams {
  id: string;
}
export interface IUnlockEbookRequestParams {
  id: string;
}

class EbookAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/ebooks', 'GET', undefined, query, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams, query: IDetailRequestQuery) => {
    const res = await Request.call('/api/ebooks/:id', 'GET', params, query, undefined, );
    return res;
  }
  saveHtml = async (params: ISaveHtmlRequestParams, body: ISaveHtmlRequestBody) => {
    const res = await Request.call('/api/ebooks/:id/save-html', 'POST', params, undefined, body, );
    return res;
  }
  applyRevision = async (params: IApplyRevisionRequestParams, body: IApplyRevisionRequestBody) => {
    const res = await Request.call('/api/ebooks/:id/apply-revision', 'POST', params, undefined, body, );
    return res;
  }
  getPages = async (params: IGetPagesRequestParams, query: IGetPagesRequestQuery) => {
    const res = await Request.call('/api/ebooks/:id/pages', 'GET', params, query, undefined, );
    return res;
  }
  savePages = async (params: ISavePagesRequestParams, body: ISavePagesRequestBody) => {
    const res = await Request.call('/api/ebooks/:id/pages', 'POST', params, undefined, body, );
    return res;
  }
  getRevisions = async (params: IGetRevisionsRequestParams, query: IGetRevisionsRequestQuery) => {
    const res = await Request.call('/api/ebooks/:id/revisions', 'GET', params, query, undefined, );
    return res;
  }
  updateDepartment = async (params: IUpdateDepartmentRequestParams, body: IUpdateDepartmentRequestBody) => {
    const res = await Request.call('/api/ebooks/:id/update-department', 'POST', params, undefined, body, );
    return res;
  }
  addLanguage = async (params: IAddLanguageRequestParams, body: IAddLanguageRequestBody) => {
    const res = await Request.call('/api/ebooks/:id/add-language', 'POST', params, undefined, body, );
    return res;
  }
  cloneEbook = async (params: ICloneEbookRequestParams, body: ICloneEbookRequestBody) => {
    const res = await Request.call('/api/ebooks/:id/clone-ebook', 'POST', params, undefined, body, );
    return res;
  }
  addAnalytics = async (params: IAddAnalyticsRequestParams, body: IAddAnalyticsRequestBody) => {
    const res = await Request.call('/api/ebooks/:id/add-analyttics', 'POST', params, undefined, body, );
    return res;
  }
  deleteEbook = async (params: IDeleteEbookRequestParams) => {
    const res = await Request.call('/api/ebooks/:id/delete-ebook', 'DELETE', params, undefined, undefined, );
    return res;
  }
  uploadZipAndValidate = async () => {
    const res = await Request.call('/api/ebooks/upload-and-validate', 'POST', undefined, undefined, undefined, );
    return res;
  }
  remoteUploadZipAndValidate = async () => {
    const res = await Request.call('/api/ebooks/remote-upload-and-validate', 'POST', undefined, undefined, undefined, );
    return res;
  }
  createEbook = async (body: ICreateEbookRequestBody) => {
    const res = await Request.call('/api/ebooks/create-ebook', 'POST', undefined, undefined, body, );
    return res;
  }
  importFromGit = async (body: IImportFromGitRequestBody) => {
    const res = await Request.call('/api/ebooks/import-from-git', 'POST', undefined, undefined, body, );
    return res;
  }
  pushEbookChangesToGit = async (body: IPushEbookChangesToGitRequestBody) => {
    const res = await Request.call('/api/ebooks/push-changes-to-git', 'POST', undefined, undefined, body, );
    return res;
  }
  checkAndPullGitChanges = async () => {
    const res = await Request.call('/api/ebooks/check-and-pull-git-changes', 'POST', undefined, undefined, undefined, );
    return res;
  }
  lockEbook = async (params: ILockEbookRequestParams) => {
    const res = await Request.call('/api/ebooks/:id/lock', 'POST', params, undefined, {}, );
    return res;
  }
  unlockEbook = async (params: IUnlockEbookRequestParams) => {
    const res = await Request.call('/api/ebooks/:id/unlock', 'POST', params, undefined, {}, );
    return res;
  }
}
export default new EbookAPI()
