import React, { useEffect, useState, useRef } from 'react';
import { IScreen, TUser } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, modal, ManageListUser, ManageListDepartments, ManageListDomains } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { TwitterPicker } from 'react-color';
import { SVG } from 'assets';
import { EvilIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';

const UpsertSite: IScreen = () => {

  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};

  const SiteStore = Store.useSiteStore();
  const { site, setSite, uiState } = SiteStore.useSite(id, id !== 'new');
  const siteRef = useRef(site);
  useEffect(() => {
    siteRef.current = site;
  }, [site]);
 
  const [{ loading: imageLoading }, setImageUI] = useUIState();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const [{ loading: removing }, setRemoveUI] = useUIState();

  const [listUsers, setListUsers] = useState<Array<TUser>>([]);

  const uploadRef = useRef<{ showDialog: Function }>(null);

  const getSiteUsers = () => {
    Store.Api.User.listUsersBySite({ id }).then((res) => {
      if (res.data.success && res.data.data) {
        setListUsers(res.data.data);
      }
    });
  }

  useEffect(() => {
    if (id === 'new') return;
    getSiteUsers();
  }, [id]);

  const onChangeField = (label) => (newValue) => {
    setSite({
      ...site,
      [label]: newValue,
    })
  }

  const submit = async (s = site, noAlert = false) => {
    setSubmitUI({ loading: true });
    try {
      const res = await Store.Api.Site.upsert({
        id: id === 'new' ? undefined : id,
        name: s?.name,
        logo: s?.logo,
        slug: s?.slug,
        theme: {
          ...s?.theme,
          mainColor: s?.theme?.mainColor || 'black'
        },
        settings: {
          ...s?.settings,
        },
        departments: s?.departments || [],
      });
      if (res.data.error) {
        alert(res.data.error);
      } else if (res.data.data) {
        if (!noAlert) alert('Successfully submitted');
        navigation.reset({
          index: 0,
          routes: [{ name: SCREEN.UpsertSite, params: { id: res.data.data.id } }],
        });
      }
    } catch (err) {
      alert(String(err));
    }
    setSubmitUI({ loading: false });
  }

  const onImageUploaded = urls => {
    console.log('urls', urls);
    if (!!urls && !!urls[0]) {
      setSite({
        ...site,
        logo: urls[0],
      })
    }
  }

  const envs = (site?.settings?.env || []);

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3>{id === 'new' ? 'Create new site' : `${site?.name}`}</Text>
        <Row>
          <Button
            isLoading={submitting}
            text={id === 'new' ? "Create" : "Save"}
            width={100} height={40} borderRadius={20}
            onPress={() => submit(site)}
          />
        </Row>
      </Row>
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={'scroll'}>
        <Grid xs='100%' md='50%' alignItems={'flex-start'} mb2>
          <Col m1>
            <Text subtitle1 mb2>Site information</Text>
            <Row alignItems={'flex-start'}>
              <Col mr1>
                <Row justifyContent={'space-between'}>
                  <Text mb0>Logo</Text>
                </Row>
                <Col width={120} height={120} backgroundColor={COLOR.GREY_LIGHT} mb1>
                  {Boolean(!!site?.logo) && (
                    <Col flex1 p1>
                      <Image source={{ uri: site?.logo }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
                    </Col>
                  )}
                  <Col absolute top={0} right={0}>
                    <TouchField cirle middle
                      onPress={() => {
                        uploadRef?.current && uploadRef.current.showDialog();
                      }}
                    >
                      <Entypo name="upload" size={15} color="black" />
                    </TouchField>
                  </Col>
                  <UploadFile
                    ref={uploadRef}
                    onUploaded={onImageUploaded}
                  />
                </Col>
              </Col>
              <Col flex1>
                <Text mb0>Name</Text>
                <Input
                  value={site?.name || ''}
                  mb1
                  onChange={onChangeField('name')}
                />
                <Text mb0>Site Slug</Text>
                <Input
                  value={site?.slug || ''}
                  mb1
                  onChange={onChangeField('slug')}
                />
                <Col mb2>
                  <Text caption>This will use to define the urls of the site</Text>
                  <Text caption lineHeight={30}>CMS: <Text p0 backgroundColor={COLOR.GREY_LIGHT}>{`${site?.slug || '[site-slug]'}.cms.prismpresent.com`}</Text></Text>
                  <Text caption lineHeight={30}>API: <Text p0 backgroundColor={COLOR.GREY_LIGHT}>{`${site?.slug || '[site-slug]'}.api.prismpresent.com`}</Text></Text>
                </Col>
              </Col>
            </Row>


            <Row mb2>
              {Boolean(site?.theme?.mainColor) && (
                <Col width={15} height={15} backgroundColor={site?.theme?.mainColor} mr1>
                </Col>
              )}
              <Text >Main Color</Text>
            </Row>
            <TwitterPicker
              color={site?.theme?.mainColor || 'black'}
              onChange={(color) => {
                setSite({
                  ...site,
                  theme: {
                    ...site.theme,
                    mainColor: color.hex,
                  }
                });
              }}
            />
            <Col mt2>
              <Text subtitle1 mb2>Site Variables</Text>
              {envs.map((val, i) => {
                return (
                  <Col mb1 key={'setting-var-item-' + i}>
                    <Row>
                      <Col flex1 mr1>
                        <Input
                          value={val.key || ''}
                          mb1
                          onChange={v => {
                            const env = envs.slice();
                            env[i].key = v;
                            setSite({
                              ...site,
                              settings: {
                                ...site?.settings,
                                env,
                              }
                            });
                          }}
                        />
                      </Col>
                      <Col flex1 mr1>
                        <Input
                          value={val.value || ''}
                          mb1
                          onChange={v => {
                            const env = envs.slice();
                            env[i].value = v;
                            setSite({
                              ...site,
                              settings: {
                                ...site?.settings,
                                env,
                              }
                            });
                          }}
                        />
                      </Col>
                      <Col width={50}>
                        <TouchField 
                          cirle middle
                          onPress={() => {
                            const env = envs.filter((fval, fI) => fI !== i);
                            setSite({
                              ...site,
                              settings: {
                                ...site?.settings,
                                env,
                              }
                            });
                          }}
                        >
                          <EvilIcons name="trash" size={24} color={COLOR.MAIN} />
                        </TouchField>
                      </Col>
                    </Row>
                  </Col>
                )
              })}
              <Button
                mb2
                outline
                height={40}
                width={150}
                text='Add new variable'
                onPress={() => {
                  const env = envs.slice();
                  env.push({
                    key: '',
                    value: '',
                  });
                  setSite({
                    ...site,
                    settings: {
                      ...site?.settings,
                      env,
                    }
                  });
                }}
              />
            </Col>
          </Col>
          <Col m1>
            {id === 'new' ? null : (
              <ManageListUser site={site} />
            )}
            {id === 'new' ? null : (
              <ManageListDepartments site={site} setSite={setSite} submit={submit} />
            )}
            {id === 'new' ? null : (
              <ManageListDomains site={site} setSite={setSite} submit={submit} />
            )}
          </Col>
        </Grid>
        <Col p1>

        </Col>
      </Col>
    </CMSLayout>
  );
};

UpsertSite.routeInfo = {
  title: 'Site manager',
  path: '/sites/:id'
};

export default UpsertSite;
