import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, Input, SwapIconEffect, Input02, Select, Select01, Select02 } from 'components';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IScreen, TEbook, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';
import { useDropzone } from 'react-dropzone';
import RequestUtils from 'store/api-client/Request.utils';
import apiClient from 'store/api-client';

const NewEbook: IScreen = () => {
  const { navigation, route } = useNavFunc();
  const [agree, setAgree] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isRemoteUploading, setIsRemoteUploading] = useState(false);
  const [isImportingFromGit, setIsImportingFromGit] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [step, setStep] = useState<'upload-html' | 'fill-info'>('upload-html');
  const [stepData, setStepData] = useState({
    tempServerPath: '',
    copyTempPath: '',
    name: '',
    remoteUrl: '',
    gitUrl: '',
  });
  const [gitUrl, setGitUrl] = useState('');
  const [selectedDomain, setSelectedDomain] = useState<{ label: string, value: string } | undefined>();
  const [subDomain, setSubDomain] = useState('');
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    const fetchDomains = async () => {
      const res = await Store.Api.Site.listDomain({ id: apiClient.Site?.id });
      setDomains(res.data.data);
    }
    fetchDomains();
  }, []);

  const remoteUploadFileAndValidate = async (url) => {
    const res = await fetch(RequestUtils.host + '/api/ebooks/remote-upload-and-validate', {
      method: 'POST',
      body: JSON.stringify({
        url
      }),
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    });
    const json = await res.json();
    setIsUploading(false);
    if (json.error) {
      alert(json.error)
    } else if (json.success) {
      setStepData({
        tempServerPath: '',
        copyTempPath: json.data.tempPath,
        name: '',
        remoteUrl: '',
        gitUrl: '',
      })
      setStep('fill-info');
    }
  }

  const uploadFileAndValidate = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const res = await fetch(RequestUtils.host + '/api/ebooks/upload-and-validate', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    });
    const json = await res.json();
    setIsUploading(false);
    if (json.error) {
      alert(json.error)
    } else if (json.success) {
      setStepData({
        tempServerPath: '',
        copyTempPath: json.data.tempPath,
        name: '',
        remoteUrl: '',
        gitUrl: '',
      })
      setStep('fill-info');
    }

  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log('acceptedFiles', acceptedFiles);
    if (acceptedFiles.length === 0) return;
    setIsUploading(true);
    uploadFileAndValidate(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const createEbook = async () => {
    if (!!selectedDomain?.value && selectedDomain.value.startsWith('*.') && !subDomain) {
      return alert('Sub domain is required for wildcard domain');
    }
    setIsCreating(true);
    try {
      const res = await Store.Api.Ebook.createEbook({
        name: stepData.name,
        tempServerPath: stepData.tempServerPath,
        copyTempPath: stepData.copyTempPath,
        ...(stepData.gitUrl && { gitUrl: stepData.gitUrl }),
        domain: selectedDomain?.value || undefined,
        subDomain,
      });
      if (res.data.success) {
        alert('Create ebook success');
        navigation.navigate(SCREEN.EditEbook, { id: res.data.data.id });
      } else {
        alert(res.data.error);
      }
    } catch (error) {
      alert('Failed to create ebook: ' + error.message);
    } finally {
      setIsCreating(false);
    }
  }

  const productionHost = () => {
    if (selectedDomain) {
      const domainWithoutWildcard = !selectedDomain.value.startsWith('*.')
        ? selectedDomain.value
        : selectedDomain.value.slice(2, selectedDomain.value.length);
      return `https://${subDomain ? subDomain + '.' : ''}${domainWithoutWildcard}/e`;
    }
    const slug = window.location.host.split('.')[0];
    if (slug === 'worldpay') return 'https://ebook.worldpaytravel.com/e';
    return slug === 'global' ? 'https://ebooks.prismpresent.com/e' : `https://${slug}.v2.prismpresent.com/e`;
  };

  const shoudAutoFillTempPath = useRef(true)

  const importFromGit = async (url: string) => {
    setIsImportingFromGit(true);
    try {
      const res = await fetch(RequestUtils.host + '/api/ebooks/import-from-git', {
        method: 'POST',
        body: JSON.stringify({ url }),
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        }
      });
      const json = await res.json();
      if (json.error) {
        alert(json.error);
      } else if (json.success) {
        setStepData({
          tempServerPath: '',
          copyTempPath: json.data.tempPath,
          name: '',
          remoteUrl: '',
          gitUrl: json.data.gitUrl, // Store the Git URL
        });
        setStep('fill-info');
      }
    } catch (error) {
      alert('Failed to import from Git: ' + error.message);
    } finally {
      setIsImportingFromGit(false);
    }
  };

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >New ebook</Text>
      </Row>
      {step === 'upload-html' && (
        <Col flex1 p2 middle>
          {!agree ? (
            <Col width={'50%'} height='50%' bgWhite shadow round1 middle p2>
              <Text bold fontSize={20}>Upload Ebook HTML</Text>
              <Col>
                <Text mv1 mb2 marginLeft={32}>The ebook structure needs to meet the following conditions:</Text>
                {[
                  'File format: .zip',
                  'Ebook must be a Single Page App (SPA)',
                  'Entry point is index.html',
                  'The div structure must be as follows:',
                  '.content -> .sections -> .pt-pages -> .pt-page -> .section -> .template',
                  'function window.onPageChange must exist and trigger when page index changes (usually in jquery.bookblock.js)',
                ].map((v, vI) => {
                  return (
                    <Row key={vI} mb0>
                      <Entypo name="dot-single" size={24} color={COLOR.MAIN} />
                      <Text ml1>{v}</Text>
                    </Row>
                  );
                })}
              </Col>
              <Button
                text="I understand"
                mt2
                width={150} height={40} borderRadius={20}
                onPress={() => {
                  setAgree(true);
                }}
              />
            </Col>
          ) : (
            <Col width={'50%'} height='50%' bgWhite shadow round1 middle p2>
              <div
                style={{
                  width: '100%',
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#f0f0f0',
                  flexDirection: 'column'
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {
                  isDragActive || isUploading ? (
                    <ActivityIndicator color="black" />
                  ) : (
                    <Entypo name="upload" size={24} color="black" />
                  )
                }
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <>
                      <p>Drag 'n' drop the zip file here, or click to select</p>
                    </>
                }
              </div>
              <Col p1 width='80%'>
                <Text center mb1>If you have a large zip file, try remote upload file using URL</Text>
                <Row width100p>
                  <Col flex1>
                    <Input02 height={40} placeholder='Upload from URL' value={stepData.remoteUrl} onChange={(newVal) => {
                      setStepData({
                        ...stepData,
                        remoteUrl: newVal,
                      })

                    }} />
                  </Col>
                  <Button
                    text="Remote upload"
                    ml1
                    isLoading={isRemoteUploading}
                    onPress={() => {
                      setIsRemoteUploading(true);
                      remoteUploadFileAndValidate(stepData.remoteUrl).finally(() => {
                        setIsRemoteUploading(false);
                      })
                    }}
                  />
                </Row>
                <Text center mb1 mt2>Or import from a Git repository</Text>
                <Text mb1>Note: The system will automatically pull changes from the repository every minute if any updates are detected.</Text>
                <Text mb1 caption>Git Url Example: https://gitlab-ci-token:glpat-1234567890@gitlab.com/prism-present/ebook-template.git</Text>
                <Row width100p>
                  <Col flex1>
                    <Input02 height={40} placeholder='Git repository URL' value={gitUrl} onChange={setGitUrl} />
                  </Col>
                  <Button
                    text="Import from Git"
                    ml1
                    isLoading={isImportingFromGit}
                    onPress={() => importFromGit(gitUrl)}
                  />
                </Row>
              </Col>

            </Col>
          )}
        </Col>
      )}
      {step === 'fill-info' && (
        <Col flex1 p2 middle>
          <Col width={'50%'} height='50%' bgWhite shadow round1 middle p2>
            <Text bold fontSize={20}>Ebook info</Text>
            <Col>
              <Text mv1 mb2>The ebook structure seems okay. Please fill in ebook name:</Text>
              {domains.length > 0 && (
                <>
                  <Col mb2>
                    <Text caption mb1>Domain:</Text>
                    <Select02
                      options={domains.map((d) => ({
                        label: d,
                        value: d,
                      }))}
                      value={selectedDomain}
                      onChange={(v) => {
                        setSelectedDomain(v);
                      }}
                    />
                  </Col>
                  {selectedDomain && selectedDomain.value.startsWith('*.') && (
                    <Col>
                      <Text caption mb0>Sub Domain:</Text>
                      <Input
                        value={subDomain}
                        onChange={setSubDomain}
                        mb2
                      />
                    </Col>
                  )}
                </>
              )}
              <Text caption mb1>Name:</Text>
              <Input
                value={stepData.name}
                onChange={(v) => {
                  let newTempServerPath
                  if (shoudAutoFillTempPath.current) {
                    newTempServerPath = v.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
                  }
                  setStepData({
                    ...stepData,
                    name: v,
                    tempServerPath: newTempServerPath || stepData.tempServerPath,
                  })
                }}
                mb2
              />

              <Text mb0>Ebook Path:</Text>
              <Text caption mb0>Your ebook will be available at: <Text color={COLOR.MAIN}>{productionHost()}/<Text bold color={COLOR.MAIN}>{stepData.tempServerPath || '{your-ebook-path}'}</Text></Text></Text>
              <Input
                value={stepData.tempServerPath}
                mb1
                onChange={(newvalue) => {
                  // convert to snake case and lower case
                  setStepData({
                    ...stepData,
                    tempServerPath: newvalue.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
                  })
                }}
                inputProps={{
                  onFocus: () => {
                    shoudAutoFillTempPath.current = false;
                  }
                }}
              />
              <Button
                text="Create ebook"
                width={150}
                height={40} borderRadius={20}
                onPress={createEbook}
                isLoading={isCreating}
              />
            </Col>
          </Col>
        </Col>
      )}

    </CMSLayout>
  )
}

NewEbook.routeInfo = {
  title: 'New Ebook',
  path: '/ebooks/new',
};

export default NewEbook;
