import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState, Button, Grid, Select02 } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator, useWindowDimensions } from 'react-native';
import { TimeHelper } from 'helpers';
import { TGeneralData, TSite } from 'type';

interface IProps {
  site: TSite,
  setSite: Function,
  onSuccess: Function,
  domain: string,
}

const UpsertSiteDomainModal = ({ site, setSite, onSuccess, domain }: IProps) => {
  const [{ loading, errorMes, fetching }, setUI] = useUIState();
  const [domainTemp, setDomainTemp] = useState(domain === 'new' ? '' : domain);

  const save = async () => {
    const domains = (site.domains || []).slice();
    const findExisted = domains.find(v => v === domainTemp);
    if (findExisted) {
      alert(`Error: The domain: ${domainTemp} has already been added!`);
      return;
    }

    setUI({ loading: true });
    try {
      const res = await Store.Api.Site.addDomain({ id: site.id }, { domain: domainTemp });
      if (!res.data.success) {
        alert(res.data.message);
        return;
      }
      if (domain === 'new') {
        domains.push(domainTemp);
      }
      const findIndex = domains.findIndex((d) => d === domain);
      if (findIndex > -1) {
        domains[findIndex] = domainTemp;
      }
      const newSite = {
        ...site,
        domains,
      }
      setSite(newSite);
      modal.hide();
      onSuccess(newSite);
    } catch (error) {
      console.log('error', error);
    } finally {
      setUI({ loading: false });
    }
  }

  return (
    <Col round1 bgWhite shadow p2 width={300}>
      <Text fontSize={16} m1 bold>{domain === 'new' ? 'New Domain' : 'Update domain: ' + domain}</Text>
      <Grid xs='100%' md='1:.' alignItems={'flex-start'}>
        <Col margin={10}>
          <Col flex1>
            <Text bod mb0>Domain need to add this DNS</Text>
            <Row backgroundColor={COLOR.GREY_LIGHT} p1 mb1>
              <Text fontSize={10}>CNAME</Text>
              <Text ml1 bold>domain.prismpresent.com</Text>
            </Row>
            <Input
              value={domainTemp}
              mb1
              onChange={v => setDomainTemp(v.trim())}
            />
            <Button
              isLoading={loading}
              width='100%'
              mb1
              height={45}
              text={domain === 'new' ? 'Create' : "Save"}
              onPress={save}
            />
          </Col>
        </Col>
      </Grid>


    </Col>
  );
};

export default UpsertSiteDomainModal;