import Request from '../Request.utils'
export interface IDetailRequestParams {
  id: string;
}
export interface IUpsertRequestBody {
  departments?: {
    id?: string;
    title?: string;
  }[];
  id?: string;
  logo?: string;
  name?: string;
  settings?: any;
  slug?: string;
  theme?: any;
}
export interface IGetDepartmentsRequestParams {
  id: string;
}
export interface IDeleteSiteRequestParams {
  id: string;
}
export interface IAddDomainRequestBody {
  domain: string;
}
export interface IAddDomainRequestParams {
  id: string;
}
export interface IDomainCheckRequestBody {
  domain: string;
}
export interface IListDomainRequestParams {
  id: string;
}
export interface IDeleteDomainRequestBody {
  domain: string;
}
export interface IDeleteDomainRequestParams {
  id: string;
}


class SiteAPI {
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/sites/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  fetchSite = async () => {
    const res = await Request.call('/api/sites/info', 'GET', undefined, undefined, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/sites', 'POST', undefined, undefined, body, );
    return res;
  }
  list = async () => {
    const res = await Request.call('/api/sites', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getDepartments = async (params: IGetDepartmentsRequestParams) => {
    const res = await Request.call('/api/sites/:id/departments', 'GET', params, undefined, undefined, );
    return res;
  }
  deleteSite = async (params: IDeleteSiteRequestParams) => {
    const res = await Request.call('/api/sites/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  addDomain = async (params: IAddDomainRequestParams, body: IAddDomainRequestBody) => {
    const res = await Request.call('/api/sites/:id/add-domain', 'POST', params, undefined, body, );
    return res;
  }
  domainCheck = async (body: IDomainCheckRequestBody) => {
    const res = await Request.call('/api/sites/check-domain', 'POST', undefined, undefined, body, );
    return res;
  }
  listDomain = async (params: IListDomainRequestParams) => {
    const res = await Request.call('/api/sites/:id/domains', 'GET', params, undefined, undefined, );
    return res;
  }
  deleteDomain = async (params: IDeleteDomainRequestParams, body: IDeleteDomainRequestBody) => {
    const res = await Request.call('/api/sites/:id/delete-domain', 'POST', params, undefined, body, );
    return res;
  }
}
export default new SiteAPI()